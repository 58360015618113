@import 'styles/layers.scss';

@layer component {
  $height: 55px;

  .wrap {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
    background: var(--black2);
  }

  .content {
    padding: 0;
    max-width: var(--max-width);
    height: $height;
  }

  .matic {
    margin-right: 13px;
  }

  .tee {
    margin-right: 20px;
  }

  .menu {
    column-gap: 64px;
  }

  .content {
    flex: 1;
  }

  .contentMobile {
    display: none;
  }

  .mr {
    margin-right: 20px;
  }

  .modal-content {
    height: 100%;
  }

  .modal-dialog {
    min-width: 525px;
  }

  .modal-body {
    height: 100%;
    padding: 22px 0 0;
  }
}
// @media screen and (max-width: 1270px) {
//   .wrap {
//     padding: 0 20px;
//   }
// }

// @media screen and (max-width: 1270px) {
//   .content {
//     display: none;
//   }
//   .contentMobile {
//     display: flex;
//     flex: 1;
//   }  
// }