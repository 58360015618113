@import 'styles/layers.scss';

@layer component {
  .groups {
    column-gap: 64px;
  }
  
  .group {
    column-gap: 20px;
  }
  
  .counter {
    margin-left: 5px;
  }

  .dropdown-toggle {
    background-color: transparent;
    padding: 0;
    color: var(--grey2);
    &:hover {
      color: var(--white);
    }
  }
  
  .dropdown-menu {
    padding: 0;
    border-radius: 6px;
    width: 115px;
    min-width: 115px;
    right: 0;
  }
  
  .dropdown-menu-item {
    height: 44px;
    width: 115px;
  }

  .dropdown-menu-item-top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  
  .dropdown-menu-item-bottom {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  .white {
    color: var(--white);
  }
  
  .text-link {
    padding-top: 1px;
  }

  .position {
    line-height: 1.008px;
    display: flex;
    align-items: center;
  }
}