@import 'styles/layers.scss';

@layer uikit {
  .flex {
    display: flex;
  }
  
  .direction-row {
    flex-direction: row;
  }
  
  .direction-column {
    flex-direction: column;
  }
  
  .direction-row-reverse {
    flex-direction: row-reverse;
  }
  
  .direction-column-reverse {
    flex-direction: column-reverse;
  }
  
  .align-items-stretch {
    align-items: stretch;
  }
  
  .align-items-baseline {
    align-items: baseline;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .align-items-flex-start {
    align-items: flex-start;
  }
  
  .align-items-flex-end {
    align-items: flex-end;
  }
  
  .justify-flex-start {
    justify-content: flex-start;
  }
  
  .justify-flex-end {
    justify-content: flex-end;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .justify-space-around {
    justify-content: space-around;
  }
  
  .justify-space-between {
    justify-content: space-between;
  }
  
  .wrap-nowrap {
    flex-wrap: nowrap;
  }
  
  .wrap-wrap {
    flex-wrap: wrap;
  }
  
  .wrap-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

