@import 'styles/layers.scss';

@layer component {
  .link {
    color: var(--grey2);
    text-decoration: none;
    display: flex;
    
    &:active, &:visited, &.active {
      color: var(--white);
    }
    &:hover {
      color: var(--white);
    }
  }
}